import { AxiosResponse } from "axios";
import Axios from "./axios";

export type GetUtilitiesAmountRequest = {
    bill_number: string;
    amount?: number;
    category: string;
    captcha: string;
    payment_method: string;
};

class Utilities {
    private axios: typeof Axios;

    constructor(axios: typeof Axios) {
        this.axios = axios;
    }

    getAmount(request: GetUtilitiesAmountRequest): Promise<AxiosResponse> {
        return this.axios.post(`/utilities/amount`, request);
    }
}

export default new Utilities(Axios);
