export interface SerializedBillAmount {
    fulfillment_amount: number;
    fulfillment_fees: number;
    payment_fees: number;
    total_amount: number;
}

export default class BillAmount {
    private readonly _fulfillmentAmount: number;
    private readonly _fulfillmentFees: number;
    private readonly _paymentFees: number;
    private readonly _totalAmount: number;
    constructor(payload: SerializedBillAmount) {
        this._fulfillmentAmount = payload.fulfillment_amount;
        this._fulfillmentFees = payload.fulfillment_fees;
        this._paymentFees = payload.payment_fees;
        this._totalAmount = payload.total_amount;
    }

    get fulfillmentAmount(): number {
        return this._fulfillmentAmount;
    }

    get fulfillmentFees(): number {
        return this._fulfillmentFees;
    }

    get paymentFees(): number {
        return this._paymentFees;
    }

    get totalAmount(): number {
        return this._totalAmount;
    }
}
